import {
  talent_staging_seek_co_nz,
  talent_staging_seek_com_au,
  ph_employer_staging_seek_com,
  sg_employer_staging_seek_com,
  id_employer_staging_seek_com,
  my_employer_staging_seek_com,
  th_employer_staging_seek_com,
  hk_employer_staging_seek_com,
  staging_seek_com_au,
  staging_seek_co_nz,
  my_staging_jobstreet_com,
  sg_staging_jobstreet_com,
  id_staging_jobstreet_com,
  hk_staging_jobsdb_com,
  th_staging_jobsdb_com,
  ph_staging_jobstreet_com,
} from '@seek/online-identity';

import type { RenderConfig } from '../../types';

import dev from './development';

export default {
  hostConfigs: {
    ...dev.hostConfigs,
    [talent_staging_seek_com_au.clientDomain]: {
      clientConfig: talent_staging_seek_com_au,
      secondaryClientDomain: staging_seek_com_au.clientDomain,
      requirePrivacyConsent: false,
      requireProtocol: true,
      environment: {},
    },
    [talent_staging_seek_co_nz.clientDomain]: {
      clientConfig: talent_staging_seek_co_nz,
      secondaryClientDomain: staging_seek_co_nz.clientDomain,
      requirePrivacyConsent: false,
      environment: {},
    },
    [ph_employer_staging_seek_com.clientDomain]: {
      clientConfig: ph_employer_staging_seek_com,
      secondaryClientDomain: ph_staging_jobstreet_com.clientDomain,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {},
    },
    [sg_employer_staging_seek_com.clientDomain]: {
      clientConfig: sg_employer_staging_seek_com,
      secondaryClientDomain: sg_staging_jobstreet_com.clientDomain,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {},
    },
    [th_employer_staging_seek_com.clientDomain]: {
      clientConfig: th_employer_staging_seek_com,
      secondaryClientDomain: th_staging_jobsdb_com.clientDomain,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {},
    },
    [hk_employer_staging_seek_com.clientDomain]: {
      clientConfig: hk_employer_staging_seek_com,
      secondaryClientDomain: hk_staging_jobsdb_com.clientDomain,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {},
    },
    [id_employer_staging_seek_com.clientDomain]: {
      clientConfig: id_employer_staging_seek_com,
      secondaryClientDomain: id_staging_jobstreet_com.clientDomain,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {},
    },
    [my_employer_staging_seek_com.clientDomain]: {
      clientConfig: my_employer_staging_seek_com,
      secondaryClientDomain: my_staging_jobstreet_com.clientDomain,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {},
    },
  },
  hookDomain: 'hirer',
  themeName: 'seekJobs',
} as RenderConfig;
