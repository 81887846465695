/* eslint-disable no-console */
import type { AuthN } from '@seek/online-identity';

import { addMetadata, track } from 'src/modules/tracking/datadog';

export const applyTrackingMetadata = async (authN: AuthN) => {
  try {
    const user = await authN.getIdentity();

    if (user) {
      addMetadata('sub', user.sub.ID);
      if (user.act) {
        addMetadata('act', user.act.ID);
      }
    }
  } catch (e) {
    track(e);
    track(`Could not apply tracking metadata.`);
  }
};
