import {
  talent_staging_seek_com_au,
  talent_staging_seek_co_nz,
  my_employer_staging_seek_com,
  sg_employer_staging_seek_com,
  id_employer_staging_seek_com,
  ph_employer_staging_seek_com,
  hk_employer_staging_seek_com,
  th_employer_staging_seek_com,
  staging_seek_com_au,
  staging_seek_co_nz,
  my_staging_jobstreet_com,
  id_staging_jobstreet_com,
  ph_staging_jobstreet_com,
  hk_staging_jobsdb_com,
  th_staging_jobsdb_com,
  sg_staging_jobstreet_com,
} from '@seek/online-identity';

import type { RenderConfig } from '../../types';

export default {
  hostConfigs: {
    'talent.seek.com.au.local': {
      clientConfig: talent_staging_seek_com_au,
      secondaryClientDomain: staging_seek_com_au.clientDomain,
      requirePrivacyConsent: false,
      environment: {
        USER_SERVICE_BASE_PATH: '',
      },
    },
    'talent.seek.com.au.test': {
      clientConfig: talent_staging_seek_com_au,
      secondaryClientDomain: staging_seek_com_au.clientDomain,
      requirePrivacyConsent: false,
      environment: {
        USER_SERVICE_BASE_PATH: '',
      },
    },
    'talent.seek.co.nz.local': {
      clientConfig: talent_staging_seek_co_nz,
      secondaryClientDomain: staging_seek_co_nz.clientDomain,
      requirePrivacyConsent: false,
      environment: {
        USER_SERVICE_BASE_PATH: '',
      },
    },
    'talent.seek.co.nz.test': {
      clientConfig: talent_staging_seek_co_nz,
      secondaryClientDomain: staging_seek_co_nz.clientDomain,
      requirePrivacyConsent: false,
      environment: {
        USER_SERVICE_BASE_PATH: '',
      },
    },
    'au.employer.seek.com.local': {
      clientConfig: talent_staging_seek_com_au,
      secondaryClientDomain: staging_seek_com_au.clientDomain,
      requirePrivacyConsent: false,
      environment: {
        USER_SERVICE_BASE_PATH: '',
      },
    },
    'nz.employer.seek.com.local': {
      clientConfig: talent_staging_seek_co_nz,
      secondaryClientDomain: staging_seek_co_nz.clientDomain,
      requirePrivacyConsent: false,
      environment: {
        USER_SERVICE_BASE_PATH: '',
      },
    },
    'my.employer.seek.com.local': {
      clientConfig: my_employer_staging_seek_com,
      secondaryClientDomain: my_staging_jobstreet_com.clientDomain,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {
        USER_SERVICE_BASE_PATH: '',
      },
    },
    'sg.employer.seek.com.local': {
      clientConfig: sg_employer_staging_seek_com,
      secondaryClientDomain: sg_staging_jobstreet_com.clientDomain,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {
        USER_SERVICE_BASE_PATH: '',
      },
    },
    'id.employer.seek.com.local': {
      clientConfig: id_employer_staging_seek_com,
      secondaryClientDomain: id_staging_jobstreet_com.clientDomain,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {
        USER_SERVICE_BASE_PATH: '',
      },
    },
    'ph.employer.seek.com.local': {
      clientConfig: ph_employer_staging_seek_com,
      secondaryClientDomain: ph_staging_jobstreet_com.clientDomain,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {
        USER_SERVICE_BASE_PATH: '',
      },
    },
    'hk.employer.seek.com.local': {
      clientConfig: hk_employer_staging_seek_com,
      secondaryClientDomain: hk_staging_jobsdb_com.clientDomain,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {
        USER_SERVICE_BASE_PATH: '',
      },
    },
    'th.employer.seek.com.local': {
      clientConfig: th_employer_staging_seek_com,
      secondaryClientDomain: th_staging_jobsdb_com.clientDomain,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {
        USER_SERVICE_BASE_PATH: '',
      },
    },
  },
  hookDomain: 'hirer',
  themeName: 'seekJobs',
} as RenderConfig;
