import type {
  Language,
  SiteNamesMatchingClassification,
} from '@seek/melways-sites';
import seekBusiness from 'braid-design-system/themes/seekBusiness';
import seekJobs from 'braid-design-system/themes/seekJobs';

import type { RenderConfig, SiteName } from './modules/configuration';
import type { Auth0TenantName } from './utils/tenants';

export interface ClientContext {
  language: string;
  site: SiteName;
  tenant: Auth0TenantName;
  environment: string;
  version?: string;
  config: RenderConfig;
}

export type LoginPath =
  | '/'
  | '/login'
  | '/register'
  | '/forgot-password'
  | '/email-lookup';

export type AuthenticationActionOrigin = 'direct' | 'apply' | 'external';

export type Locale = 'au' | 'nz' | 'hk' | 'id' | 'th' | 'my' | 'ph' | 'sg';
export type AppLanguage = Exclude<Language, 'zh'>;

export enum Experience {
  Hirer = 'hirer',
  Candidate = 'candidate',
  Business = 'business',
}

export const BRAID_THEMES = { seekJobs, seekBusiness } as const;
export type BraidThemeName = keyof typeof BRAID_THEMES;

export type EmployerSiteName = SiteNamesMatchingClassification<{
  product: 'employer';
}>;
