import type { ContextProps } from 'src/components/App/AppContext';

import { additionalLanguages } from './getLanguage';

const getLanguageSuffix = (language: string): string => {
  const [langAbbreviation] = language.split('-');
  return additionalLanguages.includes(langAbbreviation)
    ? `/${langAbbreviation}`
    : '';
};

export default (
  context: ContextProps,
  isMobileClient: boolean,
  path: string,
  isSecondaryDomain: boolean = false,
) => {
  const baseDomain =
    isSecondaryDomain && context.hostConfig.secondaryClientDomain
      ? context.hostConfig.secondaryClientDomain
      : context.hostConfig.clientConfig.clientDomain;

  const languageSuffix = getLanguageSuffix(context.language);
  const baseOrigin = `https://${baseDomain}${languageSuffix}`;
  const mobileSuffix = isMobileClient ? '?native-embed=true' : '';
  return `${baseOrigin}${path}${mobileSuffix}`;
};
