import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './components/App/App';
import { setupMetrics } from './modules/tracking/metrics';
import type { ClientContext } from './types';

export default ({
  language,
  site,
  tenant,
  environment,
  version,
  config,
}: ClientContext) => {
  setupMetrics({ environment });
  const root = createRoot(document.getElementById('app')!);

  root.render(
    <BrowserRouter>
      <App
        language={language}
        site={site}
        tenant={tenant}
        environment={environment}
        config={config}
        version={version}
      />
    </BrowserRouter>,
  );
};
