import { Logo as SeekLogo } from '@seek/logo';
import type { Brand } from '@seek/melways-sites';
import { Box } from 'braid-design-system';
import React from 'react';

import { useAppContext } from 'src/components/App/AppContext';
import type { Auth0TenantName } from 'src/utils/tenants';

import SeekBusinessLogo from './resources/SeekBusinessLogo';

export const getLogo = (tenant: Auth0TenantName, brand: Brand) => {
  switch (tenant) {
    case 'seekBusiness':
      return <SeekBusinessLogo />;
    default:
      return <SeekLogo brand={brand} />;
  }
};

const Logo = () => {
  const defaultBrand = 'seek';
  const { tenant, hostConfig } = useAppContext();
  const brand = hostConfig.clientConfig.brand ?? defaultBrand;

  return <Box display="flex">{getLogo(tenant, brand)}</Box>;
};

export default Logo;
