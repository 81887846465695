import { createForm } from '@seek/forms-ui';
import { createValidator } from '@seek/validators-js';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Card,
  ContentBlock,
  Text,
  Stack,
  Heading,
  ButtonLink,
  Button,
  Strong,
  TextLink,
  useResponsiveValue,
} from 'braid-design-system';
import React, { useMemo } from 'react';

import { useAppContext } from 'src/components/App/AppContext';
import CandidateHeader from 'src/components/shared/Header/CandidateHeader';
import { useAnalyticsContext } from 'src/modules/tracking/analytics/AnalyticsContext';
import { brandName } from 'src/utils/brandName';

import { changeLocation } from '../../../../utils/changeLocation';
import getClientHrefLink from '../../../../utils/getClientHrefLink';
import translations from '../.vocab';

import ConsentCheckbox from './ConsentCheckbox/ConsentCheckbox';
import {
  consentConfig,
  type ConsentOptionChangeTracking,
} from './ConsentCheckbox/utils';

import * as styles from './ConfirmationUI.css';

export const isEmailMasked = (email: string): boolean => {
  const regex = /@privaterelay.appleid.com$/i;
  return regex.test(email);
};

interface FormValues {
  privacyAccepted: boolean;
  marketingAccepted: boolean;
}

const { FormProvider, useField } = createForm<FormValues>();

interface ConfirmationUIProps {
  redirectUrl: string;
  email: string;
  isMobileClient: boolean;
}

type HandleSubmit<FormValuesT> = (
  handler: (formValues: FormValuesT) => void | Promise<any>,
) => (event: any) => void;

interface ConfirmationUIPropsWithHandler extends ConfirmationUIProps {
  handleSubmit: HandleSubmit<FormValues>;
}

const ConfirmationUI = ({
  redirectUrl,
  email,
  handleSubmit,
  isMobileClient,
}: ConfirmationUIPropsWithHandler) => {
  const { analytics } = useAnalyticsContext();
  const context = useAppContext();
  const brand = context.hostConfig.clientConfig.brand ?? 'seek';

  const query = useMemo(() => new URLSearchParams(window.location.search), []);
  const passwordlessAuthentication = query.get('passwordlessAuthentication');
  const isPasswordlessAuthentication = passwordlessAuthentication === 'full';
  const referrer = query.get('referrer');

  const { t } = useTranslations(translations);
  const responsiveValue = useResponsiveValue();
  const isMobileView = responsiveValue({
    mobile: true,
    tablet: false,
  });

  const isANZ = context.locale === 'au' || context.locale === 'nz';
  const privacyLink = getClientHrefLink(context, isMobileClient, '/privacy');
  const collectionNoticeLink = getClientHrefLink(
    context,
    isMobileClient,
    '/collection-notice',
  );
  const { requirePrivacyConsent, requireMarketingConsent } =
    context.hostConfig ?? {};

  const { hasConsented } = createValidator({
    test: ({ value }: { value: boolean }) =>
      !requirePrivacyConsent || value === true,
    formatErrorMessages: (messages) => () =>
      messages.social_confirmation__consent__error.format(),
    translations,
    validatorName: 'hasConsented',
  });

  const privacyAccepted = useField({
    id: 'privacyAccepted',
    validators: [hasConsented],
  });
  const marketingAccepted = useField({
    id: 'marketingAccepted',
  });

  analytics.trackPageView({
    eventName: 'email_not_found_displayed',
    currentPage: 'signin confirmation',
    isEmailMasked: isEmailMasked(email),
    ...(referrer && { identityProvider: referrer }),
    ...(isPasswordlessAuthentication && { passwordlessAuthentication }),
    isMarketingConsentOptedIn: true,
    isPrivacyConsentOptedIn: true,
  });

  const onSubmit = handleSubmit(() => {
    const queryParams = {
      accountType: 'new',
      ...(privacyAccepted.value && { privacyAccepted: privacyAccepted.value }),
      ...(marketingAccepted.value && {
        marketingAccepted: marketingAccepted.value,
      }),
    };
    const queryString = Object.entries(queryParams)
      .map((param) => param.join('='))
      .join('&');
    changeLocation(`${redirectUrl}&${queryString}`);
  });

  const handleConsentOptionChangeTracking = (
    trackingInput: ConsentOptionChangeTracking,
  ) => {
    const baseTrackingObject: Partial<ConsentOptionChangeTracking> = {};
    if (requirePrivacyConsent)
      baseTrackingObject.isPrivacyConsentOptedIn = privacyAccepted.value;
    if (requireMarketingConsent)
      baseTrackingObject.isMarketingConsentOptedIn = marketingAccepted.value;

    analytics.trackLink({
      ...baseTrackingObject,
      ...trackingInput,
    });
  };

  const Content = (
    <Box
      paddingX={{
        mobile: 'none',
        tablet: 'small',
        desktop: 'medium',
      }}
      paddingY={{ mobile: 'none', tablet: 'small', desktop: 'large' }}
    >
      <form onSubmit={onSubmit} noValidate>
        <Stack align="center" space="large">
          <Heading level="3">{t('social_confirmation__title__text')}</Heading>
          <Box width="full">
            <Stack space="xsmall">
              <Text align="center" maxLines={1}>
                {t('social_confirmation__body__text', {
                  brand: brandName(brand),
                })}{' '}
              </Text>
              <Text align="center" maxLines={1}>
                <Strong>{email}</Strong>
              </Text>
            </Stack>
          </Box>
          {isANZ ? (
            <Text size="small" align="center">
              {t('social_confirmation__privacy__text', {
                PrivacyPolicyLink: (v: any) => (
                  <TextLink href={privacyLink} target="_blank" weight="weak">
                    {v}
                  </TextLink>
                ),
                CollectionNoticeLink: (v: any) => (
                  <TextLink
                    href={collectionNoticeLink}
                    target="_blank"
                    weight="weak"
                  >
                    {v}
                  </TextLink>
                ),
              })}
            </Text>
          ) : null}
          {requirePrivacyConsent && (
            <ConsentCheckbox
              controller={privacyAccepted}
              consentProps={consentConfig.privacy}
              isMobileClient={isMobileClient}
              onCheckboxOptionChangeTracking={handleConsentOptionChangeTracking}
            />
          )}
          {requireMarketingConsent && (
            <ConsentCheckbox
              controller={marketingAccepted}
              consentProps={consentConfig.marketing}
              isMobileClient={isMobileClient}
              onCheckboxOptionChangeTracking={handleConsentOptionChangeTracking}
            />
          )}
          <Box width="full">
            <Stack align="center" space="small">
              <Button tone="brandAccent" variant="solid" type="submit">
                {t('social_confirmation__register__button')}
              </Button>
              <ButtonLink
                href={`${redirectUrl}&accountType=existing`}
                tone="neutral"
                variant="soft"
              >
                {t('social_confirmation__back_button')}
              </ButtonLink>
            </Stack>
          </Box>
        </Stack>
      </form>
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      width="full"
      height="full"
      paddingBottom="large"
      className={`${styles.container} ${styles.backgroundColors}`}
    >
      <CandidateHeader />
      <Box paddingTop="medium" height="full" display="flex" alignItems="center">
        <ContentBlock width="small">
          <Stack space="small">
            {isMobileView ? (
              <Box paddingX="small">{Content}</Box>
            ) : (
              <Card>{Content}</Card>
            )}
          </Stack>
        </ContentBlock>
      </Box>
    </Box>
  );
};

const ConfirmationUIWrapper = (props: ConfirmationUIProps) => {
  const context = useAppContext();

  return (
    <FormProvider
      initialValues={{
        privacyAccepted: true,
        marketingAccepted: true,
      }}
      language={context.language}
    >
      {({ handleSubmit }) => (
        <ConfirmationUI handleSubmit={handleSubmit} {...props} />
      )}
    </FormProvider>
  );
};

export default ConfirmationUIWrapper;
