import {
  talent_seek_com_au,
  talent_seek_co_nz,
  ph_employer_seek_com,
  hk_employer_seek_com,
  th_employer_seek_com,
  my_employer_seek_com,
  sg_employer_seek_com,
  id_employer_seek_com,
  seek_co_nz,
  seek_com_au,
  ph_jobstreet_com,
  sg_jobstreet_com,
  th_jobsdb_com,
  hk_jobsdb_com,
  id_jobstreet_com,
  my_jobstreet_com,
} from '@seek/online-identity';

import type { RenderConfig } from '../../types';

export default {
  hostConfigs: {
    [talent_seek_co_nz.clientDomain]: {
      clientConfig: talent_seek_co_nz,
      secondaryClientDomain: seek_co_nz.clientDomain,
      requirePrivacyConsent: false,
      environment: {},
    },
    [talent_seek_com_au.clientDomain]: {
      clientConfig: talent_seek_com_au,
      secondaryClientDomain: seek_com_au.clientDomain,
      requirePrivacyConsent: false,
      environment: {},
    },
    [ph_employer_seek_com.clientDomain]: {
      clientConfig: ph_employer_seek_com,
      secondaryClientDomain: ph_jobstreet_com.clientDomain,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {},
    },
    [sg_employer_seek_com.clientDomain]: {
      clientConfig: sg_employer_seek_com,
      secondaryClientDomain: sg_jobstreet_com.clientDomain,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {},
    },
    [th_employer_seek_com.clientDomain]: {
      clientConfig: th_employer_seek_com,
      secondaryClientDomain: th_jobsdb_com.clientDomain,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {},
    },
    [hk_employer_seek_com.clientDomain]: {
      clientConfig: hk_employer_seek_com,
      secondaryClientDomain: hk_jobsdb_com.clientDomain,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {},
    },
    [id_employer_seek_com.clientDomain]: {
      clientConfig: id_employer_seek_com,
      secondaryClientDomain: id_jobstreet_com.clientDomain,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {},
    },
    [my_employer_seek_com.clientDomain]: {
      clientConfig: my_employer_seek_com,
      secondaryClientDomain: my_jobstreet_com.clientDomain,
      requirePrivacyConsent: true,
      requireMarketingConsent: true,
      environment: {},
    },
  },
  hookDomain: 'hirer',
  themeName: 'seekJobs',
} as RenderConfig;
