export const brandName = (brand: string): string => {
  switch (brand.toLowerCase()) {
    case 'jobsdb':
      return 'Jobsdb';
    case 'jobstreet':
      return 'Jobstreet';
    default:
      return 'SEEK';
  }
};
