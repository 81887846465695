import { createForm } from '@seek/forms-ui';
import { Logo } from '@seek/logo';
import { createValidator } from '@seek/validators-js';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Card,
  ContentBlock,
  Text,
  Stack,
  Heading,
  Button,
  TextLink,
  useResponsiveValue,
} from 'braid-design-system';
import React from 'react';

import { useAppContext } from 'src/components/App/AppContext';

import { changeLocation } from '../../../../utils/changeLocation';
import getClientHrefLink from '../../../../utils/getClientHrefLink';
import Header from '../../../shared/Header/Header.tsx';
import translations from '../.vocab';

import ConsentCheckbox from './ConsentCheckbox/ConsentCheckbox';
import {
  consentConfig,
  type ConsentOptionChangeTracking,
  type consentPage,
} from './ConsentCheckbox/utils';

import * as styles from './ActivationConfirmationUI.css';

interface FormValues {
  privacyAccepted: boolean;
  marketingAccepted: boolean;
}

const { FormProvider, useField } = createForm<FormValues>();

interface ActivationConfirmationUIProps {
  redirectUrl: string;
}

type HandleSubmit<FormValuesT> = (
  handler: (formValues: FormValuesT) => void | Promise<any>,
) => (event: any) => void;

interface ConfirmationUIPropsWithHandler extends ActivationConfirmationUIProps {
  handleSubmit: HandleSubmit<FormValues>;
}

const ActivationConfirmationUI = ({
  redirectUrl,
  handleSubmit,
}: ConfirmationUIPropsWithHandler) => {
  const context = useAppContext();

  const { t } = useTranslations(translations);
  const responsiveValue = useResponsiveValue();
  const isMobileView = responsiveValue({
    mobile: true,
    tablet: false,
  });

  const privacyLink = getClientHrefLink(context, false, '/privacy', true);
  const collectionNoticeLink = getClientHrefLink(
    context,
    false,
    '/collection-notice',
    true,
  );
  const { requirePrivacyConsent, requireMarketingConsent } =
    context.hostConfig ?? {};

  const { hasConsented } = createValidator({
    test: ({ value }: { value: boolean }) =>
      !requirePrivacyConsent || value === true,
    formatErrorMessages: (messages) => () =>
      messages.activation_confirmation__consent__error.format(),
    translations,
    validatorName: 'hasConsented',
  });

  const privacyAccepted = useField({
    id: 'privacyAccepted',
    validators: [hasConsented],
  });
  const marketingAccepted = useField({
    id: 'marketingAccepted',
  });

  const onSubmit = handleSubmit(() => {
    const queryParams = {
      accountType: 'invited',
      ...(privacyAccepted.value && { privacyAccepted: privacyAccepted.value }),
      ...(marketingAccepted.value && {
        marketingAccepted: marketingAccepted.value,
      }),
    };
    const queryString = Object.entries(queryParams)
      .map((param) => param.join('='))
      .join('&');
    changeLocation(`${redirectUrl}&${queryString}`);
  });

  const handleConsentOptionChangeTracking = () => {
    const baseTrackingObject: Partial<ConsentOptionChangeTracking> = {};
    if (requirePrivacyConsent)
      baseTrackingObject.isPrivacyConsentOptedIn = privacyAccepted.value;
    if (requireMarketingConsent)
      baseTrackingObject.isMarketingConsentOptedIn = marketingAccepted.value;
  };
  const Content = (
    <Box
      paddingX={{
        mobile: 'none',
        tablet: 'small',
        desktop: 'medium',
      }}
      paddingY={{ mobile: 'none', tablet: 'small', desktop: 'large' }}
    >
      <form onSubmit={onSubmit} noValidate>
        <Stack align="center" space="large">
          <div className={styles.logo}>
            <Logo brand="seek" size="fill" />
          </div>
          <Heading level="3" align="center">
            {t('activation_confirmation__title__text')}
          </Heading>
          {requirePrivacyConsent ? (
            <ConsentCheckbox
              currentPage={'activation_confirmation' as consentPage}
              controller={privacyAccepted}
              consentProps={consentConfig.privacy}
              isMobileClient={false}
              onCheckboxOptionChangeTracking={handleConsentOptionChangeTracking}
            />
          ) : (
            <>
              <Text size="small" align="center">
                {t('activation_confirmation__privacy__text', {
                  PrivacyPolicyLink: (v: any) => (
                    <TextLink href={privacyLink} target="_blank" weight="weak">
                      {v}
                    </TextLink>
                  ),
                  CollectionNoticeLink: (v: any) => (
                    <TextLink
                      href={collectionNoticeLink}
                      target="_blank"
                      weight="weak"
                    >
                      {v}
                    </TextLink>
                  ),
                })}
              </Text>
            </>
          )}
          {requireMarketingConsent && (
            <ConsentCheckbox
              currentPage={'activation_confirmation' as consentPage}
              controller={marketingAccepted}
              consentProps={consentConfig.marketing}
              isMobileClient={false}
              onCheckboxOptionChangeTracking={handleConsentOptionChangeTracking}
            />
          )}
          <Box width="full">
            <Stack align="center" space="small">
              <Button tone="brandAccent" variant="solid" type="submit">
                {t('activation_confirmation__continue__button')}
              </Button>
            </Stack>
          </Box>
        </Stack>
      </form>
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      width="full"
      height="full"
      paddingBottom="large"
      className={`${styles.container} ${styles.backgroundColors}`}
    >
      <Header />
      <Box paddingTop="medium" height="full" display="flex" alignItems="center">
        <ContentBlock width="small">
          <Stack space="small">
            {isMobileView ? (
              <Box paddingX="small">{Content}</Box>
            ) : (
              <Card>{Content}</Card>
            )}
          </Stack>
        </ContentBlock>
      </Box>
    </Box>
  );
};

const ActivationConfirmationUIWrapper = (
  props: ActivationConfirmationUIProps,
) => {
  const context = useAppContext();

  return (
    <FormProvider
      initialValues={{
        privacyAccepted: true,
        marketingAccepted: true,
      }}
      language={context.language}
    >
      {({ handleSubmit }) => (
        <ActivationConfirmationUI handleSubmit={handleSubmit} {...props} />
      )}
    </FormProvider>
  );
};

export default ActivationConfirmationUIWrapper;
