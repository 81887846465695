import { useTranslations } from '@vocab/react';
import { Box, ButtonLink, Card, Stack, Text } from 'braid-design-system';
import React, { useEffect, useState } from 'react';

import { useAppContext } from 'src/components/App/AppContext';
import Header from 'src/components/shared/Header/Header';
import SecondfactorLockIllo from 'src/components/shared/SecondFactorEnrolment/SecondfactorLockIllo';
import SeekLogo from 'src/components/shared/SeekLogo/SeekLogo';
import { useAnalyticsContext } from 'src/modules/tracking/analytics/AnalyticsContext';
import { track } from 'src/modules/tracking/datadog';

import translations from './.vocab';

import * as styles from './Ticket.css';

// Ensure that the auth domain cannot be tampered with via the link by forcibly adding an / char.
// Potential threats without this include `talent.seek.com.au.anotherDomain` or `talent.seek.com.au`
export const getredirectLink = (authDomain: string, ticketLink: string) => {
  const ticketLinkMinusFirstChar = ticketLink.substring(1);
  return `https://${authDomain}/${ticketLinkMinusFirstChar}`;
};

/**
 * an intermediate page where the user clicks so that s/he can go to the MFA enrolment page
 * sample input: https://talent.seek.com.au.local:3000/oauth/ticket?link=/mfa/enrollment?ticket=gibberishGuid
 */
const Ticket = () => {
  const { analytics } = useAnalyticsContext();
  const [redirectLink, setRedirectLink] = useState<string>('');
  const { hostConfig } = useAppContext();
  const { t } = useTranslations(translations);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const ticketLink: string = query.get('link') || '';
    const linkToRedirect = getredirectLink(
      hostConfig?.clientConfig?.authDomain,
      ticketLink,
    );

    if (!ticketLink) {
      track('No link in url for 2fa enrolment intermediate page');
    }

    if (!redirectLink) {
      // the button always goes somewhere, in happy scenario (with valid ticketLink, takes user to Auth0 page)
      // in case of no ticketLink (something changes in Auth0-config and/or Auth0), user goes to seek landing page
      setRedirectLink(linkToRedirect);
    }
  }, [hostConfig?.clientConfig?.authDomain, setRedirectLink, redirectLink]);

  useEffect(() => {
    analytics.trackPageView({
      eventName: '2fa_enrolment_displayed',
      currentPage: '2fa enrolment intermediate page',
    });
  }, [analytics]);

  return (
    <Box className={styles.container}>
      <Box
        display="flex"
        justifyContent={{
          mobile: 'center',
          tablet: 'center',
          desktop: 'center',
        }}
        position="relative"
        width="full"
        flexDirection="column"
      >
        <Header />
      </Box>
      <Box display="flex" height="full" alignItems="center">
        <Box className={styles.auth0Box}>
          <Card>
            <Box paddingY="gutter">
              <Stack space="medium" align="center">
                <SeekLogo />
                <SecondfactorLockIllo height={162} />
                <Box paddingTop="medium" paddingBottom="small">
                  <Text tone="neutral" size="standard" align="center">
                    {t('mfa_ticket__body__text')}
                  </Text>
                </Box>
                <ButtonLink
                  href={redirectLink}
                  tone="formAccent"
                  variant="solid"
                >
                  {t('mfa_ticket__continue__button')}
                </ButtonLink>
              </Stack>
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default Ticket;
