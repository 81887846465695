export interface ConsentTypeProps {
  type: 'privacy' | 'marketing';
  name: string;
  id: string;
}

export const consentConfig: Record<string, ConsentTypeProps> = {
  privacy: {
    type: 'privacy',
    name: 'privacy consent',
    id: 'isPrivacyConsentOptedIn',
  },
  marketing: {
    type: 'marketing',
    name: 'marketing communications consent',
    id: 'isMarketingConsentOptedIn',
  },
  age: { type: 'privacy', name: 'age consent', id: 'isAgeConsentOptedIn' },
};

export interface ConsentOptionChangeTracking {
  currentPage: consentPage;
  eventName: 'option_selection_pressed';
  optionSelectionType: 'checkbox';
  optionSelection: 'unchecked' | 'checked';
  optionSelectionName: string;
  isPrivacyConsentOptedIn?: boolean;
  isMarketingConsentOptedIn?: boolean;
}

export type consentPage =
  | 'social-create-account-confirmation'
  | 'activation-confirmation';
