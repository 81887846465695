import { Buffer } from 'buffer';

import React, { useEffect, useState, useRef, useMemo } from 'react';

import { useAppContext } from 'src/components/App/AppContext';

import ActivationConfirmationUI from './components/ActivationConfirmationUI.tsx';
import ConfirmationUI from './components/ConfirmationUI';

const SigninConfirmation = () => {
  const { hostConfig, language, locale, tenant } = useAppContext();
  const domainName = hostConfig.clientConfig.authDomain;
  const [redirectUrl, setRedirectUrl] = useState('');
  const emailRef = useRef('');
  const query = useMemo(() => new URLSearchParams(window.location.search), []);
  const isMobileClient = query.get('isMobileClient') === 'true';

  useEffect(() => {
    const urlStateValue: string | null = query.get('state');
    const urlEmailValue: string | null = query.get('emailEnc');
    const fullLangLocale = `${language}-${locale?.toUpperCase()}`;
    const languageSuffix = isMobileClient
      ? `locale=${fullLangLocale}`
      : `language=${fullLangLocale}`;

    const userEmail: string = urlEmailValue
      ? Buffer.from(urlEmailValue, 'base64').toString('utf8')
      : 'your email';

    if (urlStateValue && userEmail) {
      emailRef.current = userEmail;
      setRedirectUrl(
        `https://${domainName}/continue?state=${urlStateValue}&${languageSuffix}`,
      );
    }
  }, [domainName, language, locale, isMobileClient, query]);

  if (!redirectUrl) return null;

  const confirmationConfig = {
    redirectUrl,
    email: emailRef.current,
    isMobileClient,
  };
  switch (tenant) {
    case 'seekAnz':
      return <ConfirmationUI {...confirmationConfig} />;
    case 'talent':
      return <ActivationConfirmationUI {...confirmationConfig} />;
    default:
      return null;
  }
};

export default SigninConfirmation;
